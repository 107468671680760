<template>
<Navbar user="0"/>
    <section class="courses-area ptb-100" v-if="!isLoading">
      <div class="container">
        <div class="courses-topbar">
          <div class="row align-items-center">
            <h2 class="text-center">All Courses</h2>
          </div>
        </div>
        <div class="shadow-lg pt-5">
          <div  v-if="courses.length">
            <div class="row  ms-2 me-2">
              <div class="col-lg-2 col-md-2 mix business design language" v-for="course in courses" :key="course.id">
                <SingleCourse :course="course"/>
              </div>
            </div>
          </div>
          <div v-else>
            <h4 class="text-center">We have No Available Course</h4>
          </div>
        </div>
      </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import SingleCourse from '@/components/SingleCourse.vue'
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
export default {
  components: { 
    Navbar, SingleCourse, Loading
  },
  data(){
    return {
      isLoading: true,
      token: localStorage.getItem('user_data'),
      errors: {},
      user: {},
      courses: {}
    }
  },
  methods:{
    getCourses(){
      axios.get('https://apitraining.vipawaworks.com/api/course/courses').then(response => {
        this.courses = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getCourses() 
  }
}
</script>

<style>

</style>